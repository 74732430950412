.tab {
	font-size: 13px;
	align-self: center;
	cursor: pointer;
	text-align: center;
}
.active {
	background-color: #0071c2;
	color: white;
}
.tabContainer {
	background-color: #f5f5f5;
	margin: 2% auto 2% auto;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border-radius: 45px;
	overflow: hidden;
	display: flex;
}
@media only screen and (max-width: 600px) {
	.tabContainer {
		margin-top: 4vh;
		width: 95vw !important;
	}
	.tab {
		padding: 2% !important;
		font-size: 3.2vw;
	}
}

/* barWidth='30vw'
tabWidth='50%' */

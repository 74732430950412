.contact {
	font-size: 90%;
	padding: 5% 0 5% 1%;
	display: flex;
}
.contact .contactInfo {
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
}
.contactMainContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}
.mobile {
	padding: 1%;
}

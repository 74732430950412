.reviewContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	color: #46484a;
}

@media only screen and (max-width: 600px) {
	.reviewContainer {
		width: 100%;
	}
}

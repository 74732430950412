html,
body {
	padding: 0;
	margin: 0;
}

body {
	user-select: none;
	font-family: 'Sora', sans-serif;
}

*::-webkit-scrollbar {
	width: 6px;
}

*::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
	border-radius: 100px;
	border-left: 0;
	border-right: 0;
	background-color: #bdbdbd;
}
*.MuiAppBar-colorPrimary {
	background-color: white !important;
}
.MuiAppBar-colorPrimary {
	background-color: white !important;
}
.app.rtl .DayPicker-NavButton--next {
	transform: rotate(180deg);
	right: 46px;
}
.app.rtl .DayPicker-NavButton--prev {
	transform: rotate(180deg);
	right: -25px;
}

@media only screen and (max-width: 450px) {
	.DayPicker-NavBar {
		padding: 0;
	}
	.DayPicker-NavButton {
		top: 0;
		width: 38px;
		height: 38px;
	}
	.DayPicker-NavButton--next {
		right: 10px;
	}
}

/* 

	body {
		height: fit-content;
		position: fixed;
	} */

/* *::-webkit-scrollbar {
	padding: 0;
	width: 25px;
	background: transparent;
}
*::-webkit-scrollbar-track {
	padding: 0;

	background: rgb(0, 0, 0, 0);
}
*::-webkit-scrollbar-thumb {
	padding: 0;

	border: 10.5px solid transparent;
	background-clip: padding-box;
	border-radius: 9999px;
	background-color: rgb(0, 113, 194, 0.7);
} */
.app {
	min-height: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

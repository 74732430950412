.form-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 600px;
	width: 90%;
	max-height: 800px;
	height: auto;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: aliceblue;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(70, 69, 69, 0.2);
	overflow-y: auto;
}

.form-scrollable {
	max-height: calc(100vh - 200px); /* Adjust the value as needed */
	overflow-y: auto;
	width: 100%;
	background-color: rgb(240, 255, 247);
}

.form-container h1 {
	color: #333;
	text-align: center;
}

.form-group {
	margin-bottom: 20px;
	width: 100%;
	max-width: 600px;
}

.form-group--horizontal {
  display: flex;
  justify-content: space-between;
}

.form-group--horizontal > * {
  width: 48%;
}

.form-item {
	display: flex;
	align-items: center;
}

.form-label {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	font-weight: 600;
	font-size: 16px;
}
.form-buttons {
	margin-top: auto;
}
.textarea-field {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	margin-bottom: 10px;
}

.input-field {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	margin-bottom: 10px;
}

.input-field-select {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	margin-bottom: 10px;
}

.checkbox-wrapper {
	cursor: pointer;
	padding: 0 10px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.checkbox {
	padding: 0 6px;
}

.button {
	border: none;
	font-size: 16px;
	cursor: pointer;
	padding: 8px;
	margin-right: 8px;
}

.button.rtl {
  margin-right: 0;
  margin-left: 8px;
}

.button-save {
	background-color: darkgreen;
	padding: 8px 13px;
	border-radius: 8px;
	color: white;
	margin-bottom: 4px;
	margin-top: 4px;
}

.button-save-item {
	margin: 0 6px;
	color: green;
}

.button-delete {
	color: #ff5c5c;
}

.error-message {
	color: red;
}

.save-button,
.discard-button {
	color: white;
	padding: 12px 20px;
	border-radius: 5px;
	border: none;
	font-size: 20px;
	margin-top: 10px;
	cursor: pointer;
}

.save-button {
	background-color: darkblue;
	margin-right: 5px;
}

.save-button.rtl {
	margin-right: 0px;
	margin-left: 0px;
}

.discard-button {
	background-color: darkred;
	margin-left: 5px;
}

.discard-button.rtl {
  margin-right: 5px;
  margin-left: 0;
}

.row-container {
    width: 99%;
    height: fit-content;
    min-height: 65px;
    background-color: rgb(230, 230, 230, 0.65);
    min-width: 300px;
    border-radius: 17px;
    display: flex;
    flex-direction: row;
    margin: 1% 4px;
    align-items: center;
    cursor: pointer;
}
.row-rtl {
    direction: rtl !important
}
.edit-container {
    background-color: #fff;
    border: 1.5px solid lightgrey;
}

.chosen {
    box-shadow: 1px 3px 0px 0px lightgrey !important;
}

.row-container>span {
    padding: 1%;
    color: grey !important;
    font-family: inherit !important;
    font-size: 13px;
}

.checked>span {
    color: black;
}

.icon-position {
    align-items: center;

    padding: 0 1.5%;
}

.folder-icons {
    display: flex;
    align-items: center;
}

.icon-position>button>span>span {
    color: #8C3AD3;
    padding: 0;
    cursor: pointer;
}

.private {
    cursor: not-allowed !important;
}

.new {
    cursor: default !important;
}

.icon-position>img {
    color: #fff;
}

.row-description {
    font-family: Soar, Sora;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    padding: 0 .5%;
    width: -webkit-fill-available;
    letter-spacing: .5px !important;
}

.mui-icons {
    margin: 0 !important;
    padding-right: 8px;
}

.mui-icons:hover {
    background-color: lightgrey;
    border-radius: 15px;
}

.edit {
    background-color: inherit;
    outline: none !important;
    border: none !important;

    resize: none;
    font-size: 15px;
    font-family: 'Sora', sens-serif;
}

.btns-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    min-width: fit-content;
    padding: 4px;
}

.btns-menu>svg,
.btns-menu>img {
    cursor: pointer;
    margin: 2%;
}

.new-lesson {
    flex-direction: row !important;
}

.folder-options {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 100px;
}

.folder-options>button {
    font-size: 13px;
    justify-content: start;
}

@media (max-width:550px) {
    .row-container {
        height: fit-content;
        min-height: 53px !important;
    }
}
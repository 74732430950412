.ActionPlanContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	color: #46484a;
	padding-bottom: 5%;
}

.blockageImg {
	width: 11%;
}

.closeButton {
	padding: 2% 0 0 0;
	width: fit-content;
	margin-left: 80%;
}

.contactNow {
	margin-top: 1%;
	display: flex;
	width: 70%;
	justify-content: center;
}

.actionsSection {
	width: 44%;
	height: 60vh;
	border-radius: 10px;
	border: 1px solid #dee3ef;
	box-shadow: rgb(0 0 0 / 10%) 2px 2px 5px;
}

/*scroll*/
/* .actionsSection .content::-webkit-scrollbar {
	width: 25px;
	background: transparent;
}
.actionsSection .content::-webkit-scrollbar-track {
	background: rgb(0, 0, 0, 0);
}
.actionsSection .content::-webkit-scrollbar-thumb {
	border: 10.5px solid transparent;
	background-clip: padding-box;
	border-radius: 9999px;
	background-color: rgb(0, 113, 194, 0.7);
} */
.actionsSection .content {
	margin-top: auto;
	overflow-y: auto;
	overflow-x: hidden;

	height: 75%;
}

.actionsSections {
	justify-content: center;
	display: flex;
	width: 100%;
	margin-bottom: 5%;
}
.actionInput:disabled {
	color: black !important;
}

.actionInput {
	font-family: inherit;
	resize: none;
	padding: 0.71rem 1.2rem 0.71rem 0.71rem;
	border-radius: 10px;
	width: 100%;
	outline: none;
	border: none;
	font-weight: bold !important;
    color: black !important;
    background-color: #F1F1FA;
}

.action img {
	border-radius: 10px;
	padding: 0.5rem;
	background-color: #f1f1f1;
	margin-right: 5%;
}

.calenderIcon {
	width: 1.47rem;
	height: 1.47rem;
}

.action.rtl {
	flex-direction: row-reverse;
}

.action {
	user-select: none;
	position: relative;
	height: fit-content;
	display: flex;
	justify-content: space-around;
	margin: 3% auto auto 3.3%;
	width: 90%;
}

.datepicker {
	background-color: #ffffff;
}

.datepicker div {
	border: none;
	height: fit-content;
}

.plusInput {
	margin-left: auto;
	padding: 0.2rem;
	justify-content: center;
	display: flex;
	align-items: center;
}

.plusIcon {
	width: 1rem;
	background-color: transparent !important;
}

.stickToTop {
	border-bottom: 1px solid #dee3ef;
	border-radius: 10px 10px 0 0;
	background-color: #ffffff;
	position: sticky;
	z-index: 15;
}

.trashButton img {
	background-color: #f1f1f1;
	padding: 0 !important;
	border: none;
	margin-left: 1px;
	border-radius: 50%;
}

.trashButton {
	opacity: 0.75;
	background-color: #f1f1f1;
	border: 1.8px solid #46484a;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 2px !important;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: #f1f1f1;
	position: absolute;
	right: -4%;
	top: 18%;
}

.trashButton,
.action img,
.plusInput {
	cursor: pointer;
}

.dueDate {
	user-select: none;
	font-size: 80%;
	margin: auto;
	text-align: right;
	margin-top: 0.5%;
	width: 84%;
}

.noActionPlan {
	text-align: center;
	opacity: 0.6;
}

.noActionPlan h3 {
	margin-top: 0;
	font-weight: lighter;
}

.noActionPlan h2 {
	margin-bottom: 1%;
}

.buttonNotification {
	padding: 1%;
	font-weight: bolder;
}

.stickToTop.rtl>div>div {
	margin-left: 0;
	margin-right: 4.5%;
}

@media only screen and (max-width: 600px) {
	.noActionPlanImg {
		width: 95%;
	}

	.actionsSections {
		width: 90vw;
		flex-direction: column;
	}

	.blockageImg {
		opacity: 0;
	}

	.actionsSection {
		width: 100%;
	}

	.planButton {
		display: none;
		max-width: 65%;
	}
}
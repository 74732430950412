.mainContainer {
	display: flex;
	flex-direction: column;
	padding: 0 5% 5% 5%;
}

.title {
	color: #46484a;
	margin-bottom: 0.5%;
}

.subTitle {
	margin: 0 0 2% 0;
	font-weight: 'lighter';
}

.goals {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 69.3vw;
	margin: auto;
	padding: 0 0 15% 0.7%;
}

.goals.rtl {
	padding: 0 0.7% 15% 0;
}

.addButton {
	margin-right: 2%;
	color: #0071c2;
}

.backdrop {
	background-color: bisque;
	position: absolute;
	width: 85%;
	height: 90%;
	z-index: 25;
	overflow-x: scroll;
}

.actionsBar {
	align-items: center;
	margin: 1% auto 1% auto;
	width: 74%;
	display: flex;
	justify-content: flex-end;
}

.dropdownContainer {
	width: 20%;
	margin-right: auto;
}

.noGoals {
	color: #787878;
	font-weight: 500;
	font-size: 200%;
	padding: 2%;
}

.actionsBar.rtl .dropdownContainer {
	margin-right: 0;
	margin-left: auto;
}

@media only screen and (max-width: 1300px) {
	.goals.rtl {
		padding: 0;
	}

	.goals {
		align-items: center;
		flex-direction: row;
		margin: auto;
		/* width: 90%; */
		/* width: 100%; */
	}

	.actionsBar {
		flex-direction: column-reverse;
	}

	.dropdownContainer {
		width: 35% !important;
	}

	.addButton {
		margin: 4% 0 4% 0;
		align-self: flex-end;
	}

	.actionsBar {
		width: 70%;
	}

	.tabs {
		align-self: center;
	}
}

@media only screen and (max-width: 820px) {
	.mainContainer {
		padding: 0;
	}

	.goals {
		padding: 0.2%;
		width: 100%;
	}

	.actionsBar {
		padding: 5%;
		width: 90%;
	}

	.tabs {
		align-self: flex-start;
	}

	.dropdownContainer {
		width: 50% !important;
	}
}

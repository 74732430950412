.phone {
    position: relative;
    display: block;
    margin: 0;
    width: 60px;
    height: 60px;
    padding: 11px;
    color: white;
    margin-right: auto;
    margin-left: auto;
    font-size: 11vmin;
    background-color: rgba(52, 171, 83 , 75%);
    border-radius: 1.5em;
    box-shadow:
        0 0 0 0em rgba(#3498db, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    transform: translate3d(0, 0, 0) scale(1);
}

.phone::before,
.phone::after {
    position: absolute;
    content: "";
}

.phone::before {
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    background-color: rgba(#fff, 0.1);
    border-radius: 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
}

.phone::after {
    top: 0.25em;
    left: 0.25em;
    width: 0.5em;
    height: 0.5em;
    /* background: url('../../') */
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translate3d(0, 0, 0);
}
.phone.isAnimating {
  animation: phone-outer 3000ms infinite;
  
  &::before {
    animation: phone-inner 3000ms infinite;
  }
  
  &::after {
    animation: phone-icon 3000ms infinite;
  }
}

.phone.isAnimating {
    animation: phone-outer 3000ms infinite;
}

.phone.isAnimating::before {
    animation: phone-inner 3000ms infinite;
}

.phone.isAnimating::after {
    animation: phone-icon 2500ms infinite;
}

@keyframes phone-outer {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
            0 0 0 0em rgba(#3498db, 0),
            0em 0.05em 0.1em rgba(#000000, 0.2);
    }

    33.3333% {
        transform: translate3d(0, 0, 0) scale(1.1);
        box-shadow:
            0 0 0 0em rgba(#3498db, 0.1),
            0em 0.05em 0.1em rgba(#000000, 0.5);
    }

    66.6666% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
            0 0 0 0.5em rgba(#3498db, 0),
            0em 0.05em 0.1em rgba(#000000, 0.2);
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
            0 0 0 0em rgba(#3498db, 0),
            0em 0.05em 0.1em rgba(#000000, 0.2);
    }
}

@keyframes phone-inner {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(0);
    }

    33.3333% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(0.9);
    }

    66.6666% {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0);
    }
}

@keyframes phone-icon {
    0% {
        transform: translate3d(0em, 0, 0);
    }

    2% {
        transform: translate3d(0.01em, 0, 0);
    }

    4% {
        transform: translate3d(-0.01em, 0, 0);
    }

    6% {
        transform: translate3d(0.01em, 0, 0);
    }

    8% {
        transform: translate3d(-0.01em, 0, 0);
    }

    10% {
        transform: translate3d(0.01em, 0, 0);
    }

    12% {
        transform: translate3d(-0.01em, 0, 0);
    }

    14% {
        transform: translate3d(0.01em, 0, 0);
    }

    16% {
        transform: translate3d(-0.01em, 0, 0);
    }

    18% {
        transform: translate3d(0.01em, 0, 0);
    }

    20% {
        transform: translate3d(-0.01em, 0, 0);
    }

    22% {
        transform: translate3d(0.01em, 0, 0);
    }

    24% {
        transform: translate3d(-0.01em, 0, 0);
    }

    26% {
        transform: translate3d(0.01em, 0, 0);
    }

    28% {
        transform: translate3d(-0.01em, 0, 0);
    }

    30% {
        transform: translate3d(0.01em, 0, 0);
    }

    32% {
        transform: translate3d(-0.01em, 0, 0);
    }

    34% {
        transform: translate3d(0.01em, 0, 0);
    }

    36% {
        transform: translate3d(-0.01em, 0, 0);
    }

    38% {
        transform: translate3d(0.01em, 0, 0);
    }

    40% {
        transform: translate3d(-0.01em, 0, 0);
    }

    42% {
        transform: translate3d(0.01em, 0, 0);
    }

    44% {
        transform: translate3d(-0.01em, 0, 0);
    }

    46% {
        transform: translate3d(0em, 0, 0);
    }
}




// loader secoin

@import "bourbon";

/* Demo Styles */

#content {
  margin: 0 auto;
  padding-bottom: 50px;
  width: 80%;
  max-width: 978px;
}

h1 {
  font-size: 40px;
}

/* The Loader */

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  
  // Modernizr no-js fallback
  .no-js & {
    display: none;
  }
}


#loader {
  display: flex;
  position: relative;
  left: 42%;
  top: 15%;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #16a085;
  animation: spin 1.7s linear infinite;
  z-index: 11;
  
  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e74c3c;
    animation: spin-reverse .6s linear infinite;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f9c922;
    animation: spin 1s linear infinite;
  }
}
.footer {
	margin-top: auto;
	display: flex;
	font-size: 1.5vh;
	color: #46484a;
	padding: 0.2%;
	justify-content: center;
	height: fit-content;
	background-color: #f9f9f9;
	z-index: 1000;
	align-items: center;
}

@media only screen and (max-width: 450px) {
	.footer {
		font-size: 9.5px;
	}
}

.footer img {
	margin-right: 0.5vh;
	align-self: center;
	height: 1.5vh;
	width: 1.5vh;
}
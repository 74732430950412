.navbarContainer {
	display: flex;
	position: fixed;
	width: 100%;
	z-index: 100;
	top: 0;
}
.navButton {
	padding-bottom: 0;
	width: 13%;
	display: flex;
	justify-items: center;
	flex-direction: column;
	color: #46484a;
	font-size: 12;
	text-transform: none !important;
	height: fit-content;
}
.navbarIcon {
	padding: 3px;
	border-radius: 6px;
	width: 1.8rem;
	height: 1.8rem;
}
.navbarIcon:hover {
	background-color: 'rgb(52, 171, 83,0.1)' !important;
}
.linkText {
	font-size: 0.9vw;
	margin: 2% auto;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.sideNavLink {
	display: flex;
	align-items: center;
	color: black;
}
.iconLinkWrapper {
	height: 30px;
	width: 30px;
	padding: 3%;
	border-radius: 10px;
	cursor: pointer;
}

.linkWrapper {
	display: flex;
	padding: 2% 0 2% 0;
}

.appBar,
.MuiAppBar-colorPrimary,
.MuiAppBar {
	box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
		rgba(17, 17, 26, 0.1) 0px 0px 8px !important;
	background-color: white !important;
}

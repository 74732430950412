.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: Sora, sens-serif;
    min-width: 250px;
    width: 92.5%;
    padding: 15px;
}

.modal-content>:first-child {
    display: flex;
    justify-content: space-between;
}

.modal-content>:first-child>img {
    width: 25px;
    cursor: pointer;
    padding: 8px;
}

.modal-content>label {
    font-size: 14px;
    font-weight: 410;
}

.titles-dropdown {
    height: 45px;
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 8px;
    margin: 0 0 8px 0;
}

.title-label {
    margin: 0;
    font-size: 14px;
    font-weight: 410;
}

.modal-title {
    padding: 6% 0;
    font-size: 20px;
    font-weight: 410;
    font-family: Sora, sens-serif;
}

.modal-input {
    padding-left: 8px;
    padding-right: 0;
    font-family: Sora, sens-serif;
    font-size: 16px;
    width: -webkit-fill-available;
    border-radius: 8px;
    height: 50px;
    margin: 5% 0;
    border: 1px solid lightgrey;
}

.alert {
    border: 2.5px solid red !important;
}

[dir="rtl"] .modal-input {
    padding-left: 0;
    padding-right: 8px;
}

.modal-input:focus {
    outline: none;
}

.modal-input.rtl {
    padding-right: 8px !important;
    padding-left: 0px !important;
    direction: rtl !important;
}

.switch-private {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.switch-private>p {
    font-weight: 410;
}

.switch-private>div {
    display: flex;
    align-items: center;
}

.switch-private>div>svg {
    margin: 0;
    cursor: default;
}

.email-box-input {
    resize: none;
    height: 50%;
    min-height: 100px;
}

.search-wrapper {
    width: 97% !important;
}

.max-permissions {
    color: red;
}

@media (max-width:550px) {
    .search-wrapper {
        width: 95%;
    }
}

.users-list-container {
    overflow-y: auto;
    height: 336.15px;
    padding-inline-start: 4px;
    margin-top: 5%;
    max-width: 469px;
    width: 100%;
    padding: 20px;
}

.access-users {
    display: flex;
    flex-direction: column;
    overflow: hidden scroll;
    height: 200px;
    width: 100%;
    min-width: 330px;
}

.user-card {
    display: flex;
    align-items: center;
    height: 48px;
    margin: 3% 0;
    justify-content: space-between;
    cursor: pointer;
}

.user-card:hover {
    background-color: #d3d3d32e;
    border-radius: 1px solid;
}

.user-info {
    height: fit-content;
    font-size: 15px;
}

.user-info>p {
    margin: 0;
}

.user-info>.name {
    font-size: 17px;
    font-weight: 450;
    font-family: Sora, sens-serif;
}

.user-info>.email {
    font-size: 14px;
    font-weight: 250;
    font-family: Sora, sens-serif;
    color: lightgrey;
}

.input-rectangle {
    border: 1px solid lightgrey !important;
    border-radius: 7px !important;
    background-color: inherit !important;
}
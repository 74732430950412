.mainContainer {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: row;
	color: #46484a;
	overflow-y: auto;
}
.id {
	color: green;
}
.languages {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 4%;
}
.languageSelector {
	cursor: pointer;
	font-weight: bolder;
	text-transform: uppercase;
	margin: 10%;
	background-color: rgba(192, 192, 192, 0.473);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2vw;
	height: 2vw;
}
.botSettings {
	padding-left: 5%;
	width: 96%;
	display: flex;
	flex-direction: column;
}
.editor {
	border-radius: 10px;

	background-color: rgba(192, 192, 192, 0.185);
	width: 100%;
}
.editorRow {
	padding: 1%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.editButtonContainer {
	margin-left: auto;
	width: 3.5rem;
	height: 3.5rem;
}
.select {
	margin: 0.5%;
	padding: 0.5%;
	display: flex;
	flex-direction: column;
	background-color: rgba(192, 192, 192, 0.151);
	width: 90%;
	overflow: hidden;
}
.select span {
	color: #0071c2;
	font-weight: bold;
	margin: 0.3% 0 0.3% 0;
}
.questionList {
	height: auto;
}
.questionList ul {
	padding: 1%;
	list-style-type: none;
	border-radius: 10px;
	background-color: rgba(192, 192, 192, 0.185);
}
.questionList ul li {
	font-size: 130%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px;
	padding: 0.5%;
	margin: 0.5%;
	background-color: rgb(223, 223, 223);
}
.input {
	margin-right: 1%;
}
.skippable {
	align-self: flex-start;
	margin-left: 5%;
}
.answerInput {
	padding: 0.5%;
	margin: 0.5%;
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 90%;
	background-color: rgba(192, 192, 192, 0.151);
}
.answersInputs {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.addAnswerButtonContainer,
.updateAnswerButtonContainer {
	margin: 0.5% 2% 0.5% 0.5%;
	align-self: flex-end;
}
.updateAnswerButtonContainer {
	display: flex;
	justify-content: center;
	width: 100%;
}
.updateAnswerButtonContainer button {
	height: 3.5rem;
	width: 15%;
	color: white !important;
}
.answerTextInputs {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.answerTitle {
	padding: 1%;
}
.deleteButton {
	margin: 1% !important;
	align-self: flex-end;
}
.mainContainer h2 {
	border-radius: 10px 10px 0 0;
	padding: 1.2%;
	background-color: rgba(192, 192, 192, 0.185);
	margin: auto;
	color: green;
}
.directionButtonContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
/* .answerTextInputs div {
	background-color: blue;
	padding: 1%;
	width: 100%;
	display: flex;
} */

#scroll-text {
    height: 100%;
    text-align: center;
}

#scroll-text:hover {
    height: 100%;
    display: inline;
    white-space: normal;
    text-overflow: clip;
    overflow: visible;
    position: relative;
    padding: 0;
    // -moz-transform: translateY(100%);
    // -webkit-transform: translateY(100%);
    transform: translateY(45%);

    // -moz-animation: my-animation 5s linear infinite;
    // -webkit-animation: my-animation 5s linear infinite;
    animation: my-animation 4.5s linear infinite;
}


@-webkit-keyframes my-animation {
    from {
        -webkit-transform: translateY(45%);
    }

    to {
        -webkit-transform: translateY(-50%);
    }
}

@keyframes my-animation {
    from {
        // -moz-transform: translateY(100%);
        // -webkit-transform: translateY(100%);
        transform: translateY(45%);
    }

    to {
        // -moz-transform: translateY(-100%);
        // -webkit-transform: translateY(-100%);
        transform: translateY(-50%);
    }
}
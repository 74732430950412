.blockageClosedIcon {
	width: 10px;
	margin: 0 2% 0 2%;
}
.blockageClosedContainer {
	font-weight: 900;
	display: flex;
	justify-content: center;
	align-self: center;
	margin: auto;
}

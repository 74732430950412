.ActionPlanContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	color: #46484a;
	padding-bottom: 5%;
}

.blockageImg {
	width: 11%;
}

.closeButton {
	padding: 2% 0 0 0;
	width: fit-content;
	margin-left: 80%;
}

.checkIcon {
	cursor: pointer;
	color: green;
}

.checkIcon>.MuiSvgIcon-root {
	color: lightgreen;
	width: 32px;
	height: 32px;
}

.contactNow {
	margin-top: 1%;
	display: flex;
	width: 70%;
	justify-content: center;
}

.actionsSection {
	width: 456px;
	height: 100%;
	position: relative;
	min-height: 570px;
	border-radius: 13.6px;
	border: 1.5px solid rgba(49, 49, 49, 0.15);
	box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.actionSection_contents {
	position: absolute;
	top: 13%;
	right: 0;
	left: 0;
	bottom: 0;
}

.mainButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	top: 10;
}

.participant .userInfo {
	padding-top: 1%;
	margin-left: 4.5%;
}

.participant .userInfo .fullName {
	font-weight: bolder;
	font-size: 150%;
	font-family: Sora, sans-serif;
	font-weight: 700;
	color: rgba(70, 72, 74, 1);
	font-size: 23.12px;
}

.title {
	width: 166px;
	height: 23px;
	font-family: Sora, sans-serif;
	font-weight: 400;
	font-size: 18.12px;
}

.avatar {
	width: 52px !important;
	height: 52px !important;
}

.avatar.small {
	width: 48px !important;
	height: 48px !important;
}

.participant {
	margin-top: 22px;
	display: flex;
	align-items: center;
	justify-items: center;
	text-align: center;
	justify-content: center;
}

.content {
	height: 35%;
	padding-top: 0.5%;
}

.content>p {
	margin-inline-start: 3%;
}

.datePicker {
	position: absolute;
	background-color: white;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.datePicker>* {
	border: none !important;
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.rtlMobileContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.effect {
	animation-name: example;
	animation-duration: 1.5s;
}

.error {
	color: red;
	font-size: 14px;
	font-weight: 450;
}

@keyframes example {
	0% {
		box-shadow: 10px 10px 10px 10px lightgrey;
		border: 0.5px solid dodgerblue
	}

	100% {
		box-sizing: none;
		border: none
	}
}

.actionsSections {
	justify-content: space-around;
	display: flex;
	width: 100%;
	margin-bottom: 5%;
	gap: 10px;
}

.actionInput {
	font-family: Sora, sans-serif;
	resize: none;
	padding: 0.71rem 1.2rem 0.71rem 0.71rem;
	border-radius: 10px;
	width: 100%;
	outline: none;
	border: none;
	background-color: #f1f1f1;
}

.action img {
	border-radius: 10px;
	padding: 0.5rem;
	background-color: #f1f1f1;
	margin-right: 5%;
}

.calenderIcon {
	width: 1.47rem;
	height: 1.47rem;
}

.action.rtl {
	flex-direction: row-reverse;
}

.action {
	margin-top: 32px;
	user-select: none;
	position: relative;
	height: fit-content;
	display: flex;
	justify-content: space-around;
	margin: 32px auto auto 3.3%;
	width: 90%;
}

.rtlAction {
	margin-top: 32px;
	user-select: none;
	position: relative;
	height: fit-content;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-around;
	margin: 32px auto auto 3.3%;
	width: 90%;
}

.todoHeader {
	display: flex;
	justify-content: space-between;
}

.presentTask {
	display: flex;
	background-color: rgba(241, 241, 241, 1);
	border-radius: 10px;
	margin: 32px 4px 0;
	min-height: 74px;
	flex-direction: column;
	justify-content: space-between;
}

.rltMobilePresentTask {
	margin: 32px 4% 0 14px;
	display: flex;
	flex-direction: column;
	background-color: rgba(241, 241, 241, 1);
	border-radius: 10px;
	min-height: 74px;
	/* width: 92%; */
	justify-content: space-between;
}

.todoTimes {
	height: 1.2rem;
	margin: 1% 2% 0 7%;
	display: flex;
	font-size: 14px;
	justify-content: space-between;
}

.todoTimes>p,
.todoTimes>span {
	margin: 0;
	font-weight: bold;
}

.todoTimes>p>span {
	font-weight: normal;
}

.todoTimesRtl {
	margin: 1% 6.8% 0 2% !important;
}

.checkbox {
	cursor: pointer;
	width: 40px;
	height: 40px;
	opacity: 0.3
}

.restoreButton {
	cursor: pointer;
}

.restoreSection {
	display: flex;
	justify-content: end;
	justify-items: center;
	flex-direction: row-reverse;
	width: 55px;
	padding: 2%;
	margin-top: auto;
	margin-bottom: auto
}

.restoreButton:hover {
	opacity: 1;
	color: rgb(0, 185, 62);
	font-size: 33px;
	font-weight: bold;
}

.displayDeleteBtn {
	width: 20px;
	border-radius: 50%;
	height: 20px;
	background-color: #a02b2b;
	border: none;
	top: 2%;
	left: 2%;
	position: absolute;
}

.skeletons {
	display: flex;
	top: 2%;
	left: 2%;
	position: relative;
	margin: 32px 4px 0;
	background-color: rgba(241, 241, 241, 1);
}

.displayDeleteBtnActive {
	background-color: red;
	width: 20px;
	border-radius: 50%;
	height: 20px;
	border: none;
	left: 2%;
	top: 2%;
	position: absolute;
}

.loadDeletedBtnWrapper {
	display: flex;
	justify-content: center;
	position: relative;
	top: 50%;
}

.loadDeletedTasks {
	width: 150px;
	height: 45px;
	border: none;
	background-color: skyblue;
	outline: none;
	cursor: pointer;
}

.loadDeletedTasks:active {
	box-shadow: 1px 4px 6px 7px lightgrey;
}



.userIcon {
	position: absolute;
	right: 0;
	top: 4px;
}

.indicator {
	width: 3px;
	height: 3px;
	margin-left: 8px;
	margin-bottom: auto;
	margin-top: auto;
	color: rgba(168, 168, 168, 1);
	top: 34.5% !important
}

.dropdown {
	padding: 0 6%;
	font-weight: bold;
}

.dropdown>select {
	border: none;
	outline: none;
	background-color: inherit;
	color: inherit;
}

.taskDueDate {
	width: fit-content;
	font-family: Sora, sans-serif;
	font-weight: 600;
	font-size: 14px;
	font-style: normal;
}

.taskDueDate>span {
	text-align: 'right'
}


.taskDescription {
	font-family: Sora, sans-serif;
	font-weight: 400;
	font-size: 18.12px;
	margin-left: 30px;
	margin-right: 32px;
	background-color: inherit;
	border: none;
	word-break: break-word;
	overflow: hidden;
	resize: none;
	max-width: 100%;
}

.rtlMobileDescription {
	margin: 0 20px !important;
	font-family: Sora, sans-serif;
	font-weight: 400;
	font-size: 18.12px;
	background-color: inherit;
	border: none;
	word-break: break-word;
	overflow: hidden;
	resize: none;
	max-width: 100%;
}

.taskDescription:focus {
	border: none;
	outline: none;
}

.taskDueDate span {
	font-weight: 100
}

.editMode {
	float: right;
	margin-top: 35px
}

.taskIcons {
	display: flex;
	justify-content: space-around;
	justify-items: center;
	flex-direction: row-reverse;
	width: 55px;
	padding: 1% 2%;
	margin-top: auto;
	margin-bottom: auto
}

.rtlMobileTaskIcons {
	display: flex;
	justify-content: space-around;
	justify-items: center;
	flex-direction: row-reverse;
	width: 55px;
	padding: 2%;
	margin-top: auto;
	margin-bottom: auto
}

.icon {
	cursor: pointer;
	width: 25px;
	height: 25px;
	color: #000;
}

.taskIcons span {
	margin-left: 10px
}

.datepicker {
	background-color: #ffffff;
}

.datepicker div {
	border: none;
	height: fit-content;
}

.plusInput {
	margin-left: auto;
	padding: 0.2rem;
	justify-content: center;
	display: flex;
	width: 78%;
	height: 33px;
}

.saveButton {
	width: 1rem;
	background-color: transparent !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.saveButton:hover {
	color: green;
}

.trashButton img {
	background-color: #f1f1f1;
	padding: 0 !important;
	border: none;
	margin-left: 1px;
	border-radius: 50%;
}

.trashButton {
	opacity: 0.75;
	background-color: #f1f1f1;
	border: 1.8px solid #46484a;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 2px !important;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: #f1f1f1;
	position: absolute;
	right: -4%;
	top: 18%;
}

.trashButton,
.action img,
.plusInput {
	cursor: pointer;
}

.dueDate {
	user-select: none;
	font-family: Sora, sans-serif;
	font-size: 80%;
	margin: auto;
	text-align: right;
	margin-top: 0.5%;
	width: 84%;

}

.rtlDueDate {
	user-select: none;
	font-family: Sora, sans-serif;
	font-size: 80%;
	margin: none;
	text-align: left;
	margin-top: 0.5%;
	width: 84%;
	display: flow-root;
	margin-left: 350px;
}

.noActionPlan {
	text-align: center;
	opacity: 0.6;
}

.noActionPlan h3 {
	margin-top: 0;
	font-weight: lighter;
}

.noActionPlan h2 {
	margin-bottom: 1%;
}

.buttonNotification {
	padding: 1%;
	font-weight: bolder;
}

.stickToTop.rtl>div>div {
	margin-left: 0;
	margin-right: 4.5%;
}

@media only screen and (max-width: 820px) {
	.noActionPlanImg {
		width: 95%;
	}

	.actionsSections {
		width: 90vw;
		flex-direction: column;
	}

	.blockageImg {
		opacity: 0;
	}

	.actionsSection {
		width: 100%;
	}

	.planButton {
		display: none;
		max-width: 65%;
	}

	.taskDescription {
		margin-left: 1px !important;
		margin-right: 1px !important;
	}

}

@media only screen and (min-width: 360px) {
	.actionsSection {
		margin-top: 8px
	}
}

@media only screen and (min-width: 412px) {
	.container {
		width: 100%
	}

	.presentTask {
		width: 95%;
	}
}

@media only screen and (min-width: 1368px) {
	.presentTask {
		width: 95%;
	}
}
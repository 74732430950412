.confirmationContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	color: #46484a;
	padding-bottom: 5%;
}
.lastPraise {
	width: 100%;
	padding: 2% 0 2% 0;
	font-weight: 500;
	align-self: flex-start;
	font-size: 120%;
}
.lastPraise span {
	font-size: 80%;
	color: #5dc067;
}
.participants {
	margin: 2% 0 5% 0;
	justify-content: center;
	align-items: center;
	display: flex;
	width: 100%;
}

.framed {
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border: 1px solid #dee3ef;
}

.participant {
	border-radius: 10px;
	padding: 2%;
	width: 100%;
	display: flex;
}
.participants .blockageImg {
	width: 6rem;
}
.participant .userInfo {
	padding-top: 1%;
	margin-left: 4.5%;
}
.participant .userInfo .fullName {
	font-weight: bolder;
	font-size: 150%;
}
.avatar {
	width: 60px !important;
	height: 60px !important;
}
.avatar.small {
	width: 48px !important;
	height: 48px !important;
}
.divider {
	width: 100%;
	border-bottom: 1px solid #cccccc;
}
.confirmationActions {
	margin-top: 5%;
	border-left: 1px solid #c1c1d9;
	width: 100%;
}

.question .answer {
	margin: 1rem 0 1rem 0;
	justify-content: space-between;
	display: flex;
}
.question .info {
	margin-left: 2rem;
	margin-bottom: 1rem;
	border-radius: 10px;
	padding: 1.5rem;
	background-color: #f6f6f6;
	box-shadow: rgb(0 0 0 / 10%) 2px 2px 5px;
}
.question .answer h2 {
	font-size: 140%;
	margin: 1.5% 0 1.5% 0;
}
.question .answer p {
	margin: 0;
	padding: 0;
}
.toggleGroup {
	margin-top: auto;
	align-self: center;
	background-color: #ffffff !important;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}



.button {
	padding: 3% 3.5% 3% 3.5% !important;
	font-size: 1.3rem !important;

	color: #46484a !important;
	/* color: #c1c1d9 !important; */
	width: 7rem;
	text-transform: none !important;
	
}
.button.active {
	font-weight: bolder;
	color: #ffffff !important;
	background-color: #00c04d !important;
}
.button.active.negative {
	background-color: #ff4662 !important;
}
.borderCircle {
	position: relative;
	left: -0.415rem;
	border-radius: 50%;
	width: 12px;
	height: 12px;
	background-color: #c1c1d9;
}
.answer .content {
	position: relative;
	display: flex;
	flex-direction: column;
}
.okayIcon {
	width: 1.7rem;
	height: 1.7rem;
	position: absolute;
	left: -4.4rem;
	top: 0.6rem;
}

.redOkayIcon {
	position: relative;
	margin-right: 1%;
}
.infoTitle {
	font-weight: bolder;
	font-size: 110%;
}
.infoTitle,
.infoRow {
	align-items: flex-start;
	display: flex;
	margin-bottom: 1%;
}
.contact {
	font-size: 90%;
	padding: 5% 0 5% 1%;
	display: flex;
}
.contact .contactInfo {
	align-items: center;
	display: flex;
	flex-direction: column;
}
.buttonsContainer {
	justify-content: center;
	display: flex;
	flex-direction: column;
}
.freeText {
	font-weight: 500;
	padding-left: 1.7rem;
	color: #0071c2;
}
.question.rtl .info .answer .content .okayIcon {
	width: 1.7rem;
	height: 1.7rem;
	left: 0rem;
	right: -4.4rem;
	top: 0.6rem;
}
.confirmationActions.rtl {
	margin-top: 5%;
	border-left: none;
	border-right: 1px solid #c1c1d9;
	width: 100%;
}
.question.rtl .info {
	margin-left: 0;
	margin-right: 2rem;
}
.confirmationContainer.rtl .borderCircle {
	left: 0.4rem;
}
.buttonsContainer.rtl .toggleGroup {
	flex-direction: row-reverse;
}
.confirmationContainer.rtl .redOkayIcon {
	margin-right: 0;
	margin-left: 1%;
}
.confirmationContainer.rtl .participants .participant .userInfo {
	margin-left: 0;
	margin-right: 4.5%;
}
.confirmationContainer .participants .participant .userInfo {
	margin-right: 0;
	margin-left: 4.5%;
}
@media only screen and (max-width: 600px) {
	.framed {
		box-shadow: none;
		border: none;
	}

	.confirmationContainer .participants .participant {
		padding-left: 0.5%;
		justify-content: space-between;
		width: 40%;
		overflow: auto;
		flex-direction: column;
	}
	.participants .blockageImg {
		width: 4.5rem;
		align-self: flex-start;
		margin-top: 5%;
	}
	.confirmationContainer .participants .participant .userInfo,
	.confirmationContainer.rtl .participants .participant .userInfo {
		text-align: center;
		font-size: 80%;
		margin-left: 0;
		margin-right: 0;
	}

	.confirmationContainer .participants {
		background-color: #f4f9ff;
		justify-content: space-between;
		align-items: flex-start;
		width: 106.5%;
	}
	.confirmationContainer .participants .participant .avatar {
		align-self: center;
	}
	.divider {
		display: none;
	}
	.confirmationActions {
		margin-top: 1%;
		border-left: none;
	}
	.okayIcon {
		display: none;
	}
	.borderCircle {
		display: none;
	}
	.question .info {
		padding: 1rem;
		margin-left: 0;
	}
	.question .answer {
		align-items: flex-start;
		flex-direction: column;
		margin-bottom: 0;
	}
	.buttonsContainer {
		padding: 5%;
		justify-content: flex-start;
	}
	.question .answer p {
		display: none;
	}
	::-webkit-scrollbar {
		/*for devlopment only*/
		display: none;
	}
}

.bar {
    float: left;
    width: 15px;
    height: 6px;
    border-radius: 2px;
    background-color: white;
    animation: loading 1s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
    margin-top: 35px;
}

@media only screen and (max-width:480px) {
    .bar {
        margin-top: 17px
    }
}

@keyframes loading {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(80px, 0);
        background-color: white;
        width: 25px;
    }
}
.btn {
    background: #111;
    color: #fff;
    border: none;
    transition: all 1s;
    padding: 1em 0em 1em 3em;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: .05em;
    border-radius: 2em;
    position: relative;
    display: inline-block;
    height: 1em;
    width: 0;
    max-width: 0;

    i {
        position: absolute;
        left: .75em;
        top: .75em;
    }

    i.fa {
        font-size: 1.25em;
    }

    .text {
        cursor: default;
        display: flex;
        opacity: 0;
        transition: all .2s;
        transition-delay: 0s;
        width: 54px;
        min-width: 54px;
        transform: translateX(.5em);
        flex-direction: row;
    }
}

.btn.expand,
.expand-on-hover:hover {
    padding: 1em 2em 1em 3em;
    max-width: 10em;
    width: auto;
    cursor: default;

    .text {
        opacity: 1;
        transition-delay: .2s;
        min-width: inherit;
        transform: translateX(0);
        font-family: Sora, sans-serif;
        display: 'flex';
    }
}

.periods.close {
    border: none;
    left: .7em;
    top: 0em;
}

.MuiSvgIcon-root {
    position: relative;
    top: -10%;
    margin: 0 0.8rem 0 0;
    cursor: pointer;
}

.periods {
    height: 1.25em;
    width: 1.25em;
    border: 2px solid #fff;
    display: inline-block;
    border-radius: 2em;

    &::before {
        content: '...';
        font-size: 23px;
    }
}
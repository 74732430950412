.main {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: -webkit-fill-available;
    margin-bottom: auto;
    font-size: 16px;
    font-family: 'Sora', sans-serif;
}

.main>p {
    width: 150px;
    margin: 1.3% .5%;
}

.section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.lessons-section {
    grid-template-columns: auto !important;
}

.title-inline-input {
    margin: 0;
    width: fit-content;
    height: 34px;
    border-radius: 8px;
    outline: none;
    border: 1px solid lightgrey;
}

@media (max-width:550px) {
    .section {
        grid-template-columns: auto !important;
    }
}

@media (min-width: 820px) {
    .section {
        grid-template-columns: auto auto !important;
    }
}

@media (min-width: 1100px) {
    .section {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .lessons-section {
        grid-template-columns: auto !important;
    }
}
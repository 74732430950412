.stickbar {
    width: 100%;
    height: 75px;
    position: static;
    top: 75px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 0 .5%;
    direction: ltr;
    padding: 0 0 0 1% !important;
}

.stickbar-rtl {
    direction: rtl !important;
    padding: 0 0 0 0 !important;
}

.animated-bar {
    animation-name: slide-up;
    animation-duration: .5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    transform: translateY(100%);

    position: sticky;
    bottom: 0;
    background-color: #fff;
    border-top: 1.5px solid #DFDFDF !important;
}

.bar-name {
    display: flex;
    width: 100%;
    align-items: center;
    font-family: Sora, sens-serif;
    font-style: normal;
    font-weight: 410;
    font-size: 21.5px;
    line-height: 28px;
}

.bar-name>p {
    margin: 0;
    text-align: left;
    height: 28px;
    width: 95%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.bar-name>p.rtl {
    text-align: right;
}

.back-arrow {
    cursor: pointer;
    padding: 0 !important;
}

.bar-btns {
    display: flex;
    justify-content: space-between;
    max-width: 450px;
    padding: 0 1.6%;
    gap: 3%;
}

.disabled {
    background-color: grey !important;

}

.copy-item {
    font-weight: 300;
    font-size: 19px;
}

@media (max-width:550px) {
    .bar-btns {
        max-width: 232px !important;
    }
}
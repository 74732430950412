.mainConatiner {
	width: 90vw;
}
.content {
	margin: auto;
	width: 67%;
}
.topContainer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background-color: white;
	display: flex;
	width: 100%;
	box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
		rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.closeButton {
	justify-content: center;
	align-content: center;
	margin-left: auto;
	margin-right: 2%;
	display: flex;
	align-self: center;
}
.topContainer.rtl .closeButton {
	margin-left: 2%;
	margin-right: auto;
}
.stepBar {
	font-size: 100%;
	justify-content: flex-end;
	display: flex;
	width: 65%;
	align-self: center;
}

/* @media only screen and (min-width: 2000px) {
	.mainConatiner .content {
		margin: auto;
		width: 70%;
	}
} */

@media only screen and (max-width: 600px) {
	.mainConatiner {
		width: 100%;
	}
	.mainConatiner .content {
		padding-top: 20%;
		margin: auto;
		width: 100%;
	}
	.closeButton {
		right: -1.5%;
		bottom: 46%;
		position: absolute;
		width: fit-content;
	}
	.topContainer {
		justify-content: center;
		width: 100%;
	}
	.stepBar {
		margin-top: 5%;
		width: 100%;
		justify-content: center;
	}
}

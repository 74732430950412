.wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 77px;
	/* 
    [breakpoints.down('sm')]: {
        marginTop: '10vh',
    },
    [breakpoints.down('md')]: {
        marginTop: '10vh',
    },
    [breakpoints.down('lg')]: {
        marginTop: '14vh',
    }, */
}

.container {
	overflow-y: auto;

	/* [breakpoints.up('md')]: {
        width: '100%',
        marginInlineStart: 'auto',
    }, */
}
@media only screen and (max-width: 450px) {
	.wrapper {
		margin-top: 55px;
	}
}

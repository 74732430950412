.summeryTable-container {
    width: 100%;

    /* display: flex; */
    justify-content: center;
    gap: 2%;
}

.table-card-container {
    flex: 1 1 100%;

    border-top: 1px solid #ffccff;


}

.table-card-container>* {
    border-right: 1px solid #ffccff;
    border-left: 1px solid #ffccff;
}

.add-lesson_input {
    display: flex;
}

.add-lesson_input>input {
    flex: 1;
}

/* .addMP {
    cursor: pointer;
    position: relative;
    left: 20%;
} */

.table-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;

    background-color: #ffccff37;

    position: relative;
}

.table-card-header>:first-child {
    font-size: 2rem;
    font-weight: bold;

    color: #7030A0;

}

.table-card-header>:last-child {
    margin: 1% 0;
}

.table-card-container_lesson {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    border-top: 1px solid #ffccff;
    border-bottom: 1px solid #ffccff;
}

.table-card-container_lesson:nth-of-type(even) {
    background-color: #ffccff37;
}

span>img {
    width: 1rem;
    cursor: pointer;

    position: absolute;
    top: 25%;
    bottom: 25%;
}

.trash {
    width: 1rem;

}

.date_wrapper>:last-child {
    margin-inline-start: 10%;
}


.table-card-container_lesson_info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    position: relative;
}

.table-card-container_lesson_info>:nth-child(1) {
    flex: 2 1 100%;
    padding: 2%;
}

.table-card-container_lesson_info>:nth-child(2) {
    flex: 1 1 30%;
    border-left: 1px solid #ffccff;
    border-right: 1px solid #ffccff;
    text-align: center;


}

.table-card-container_lesson_info>:nth-child(3) {
    flex: 1 1 57%;
    text-align: center;
}

.table-card-container_lesson_info>:nth-child(3),
.table-card-container_lesson_info>:nth-child(2) {
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-card-container-lessons>:last-child {
    padding: 2% 0;
    text-align: center;
    cursor: pointer;
}

.table-card-container-lessons>:last-child {
    width: 100%;
    border-bottom: 1px solid #ffccff;
    background-color: #80808008;
}

.isLeader {
    background-color: #80808008;
}

.renderTitlePlusIcon {
    position: relative;
}

@media screen and (max-width: 1400px) {

    .table-card-container_lesson_info>:nth-child(2),
    .table-card-container_lesson_info>:nth-child(3) {
        flex: 1 1 65%;
        font-size: 0.5rem;
    }
}

@media screen and (max-width: 600px) {
    .summeryTable-container {
        height: 60%;
        overflow-y: auto;
        
        display: flex;
        flex-direction: column; 
        justify-content:unset;
        align-items: center;
        gap: 0;
    }

    .table-card-header {
        text-align: center;
    }

    .table-card-container_lesson_info>:nth-child(2),
    .table-card-container_lesson_info>:nth-child(3) {
        flex: 1 1 35%;
        font-size: 0.5rem;
    }

    span>img,
    div.table-card-container_lesson_info>img {
        width: 1rem;

    }
}
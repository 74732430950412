.lessons-btn {
    width: 160px;
    min-width: 110px;
    position: relative;
    cursor: pointer;
    display: flex;
    height: 40px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 2px;
}

.disabled {
    background-color: grey;
    cursor: not-allowed;
}

.lessons-btn.new,
.lessons-btn.new-folder,
.lessons-btn.plus-lesson {
    color: #7E4FB9;
    border: none;
    background-color: #E2DFF7;
}

.lessons-btn.save {
    color: white;
    border: none;
    background-color: rgba(126, 79, 185, 0.4);
    height: 35px !important;
    width: 57px;
    min-width: fit-content !important;
}

.lessons-btn.paste-here {
    color: white;
    border: none;
    background-color: rgba(126, 79, 185);
}

.lessons-btn.cancel,
.lessons-btn.edit-folder,
.lessons-btn.title,
.lessons-btn.grant-permissions,
.lessons-btn.send-email {
    color: black;
    border: 2px solid lightgrey;
    height: 38px !important;
    background-color: #fff;
}

.lessons-btn>p {
    margin: 0;
    font-weight: 550;
}

.bar-btns {
    display: flex;
    justify-content: space-between;
    max-width: 450px;
    padding: 0 2%;
    gap: 3%;
}

.bar-btns.rtl {
    padding: .5% !important;
}

.fotter-btn {
    width: 209px;
}

.new-lesson,
.lessons-btn.save {
    min-width: 80px !important;
    height: 40px !important;
    gap: 3%;
}

.lessons-btn.edit-lesson {
    height: 25px !important;
}

.popover {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 100px;
}

.popover>button {
    font-size: 13px;
    justify-content: start;
}

@media (max-width:550px) {
    .lessons-btn {
        min-width: 107px;
        width: 20%;
    }

    .lessons-btn>p {
        font-size: 12px;
    }

    .new-lesson,
    .lessons-btn.save {
        height: 30px !important;
    }
}
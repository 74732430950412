.dropdown {
    position: absolute;
    border: 1px solid rgb(165, 154, 154, 0.8);
    top: 24.5%;
    width: 7%;
    border-radius: 5px;
    right: 16%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.dropdown-rtl {
    right: 0 !important;
    left: 16%;
}

.dropdown-rtl>button {
    text-align: right !important;
    padding-right: 9% !important;
    padding-left: 0;
}

.dropdown>button {
    background-color: #fff;
    outline: none;
    border: none;
    font-size: 14px;
    margin: 1% 0;
    height: 40px;
    text-align: left;
    padding-left: 9%;
    width: fit-content;
    border-radius: 10px;
}

.stick-buttons {
    position: sticky !important;
    height: fit-content;
    bottom: 0;
    /* background-color: white; */
}

/* .addition-modal-style {
    padding: 15px 15px 0 15px !important;
} */

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}
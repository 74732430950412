@import "bourbon";

#loader {
    display: flex;
    position: relative;
    left: 42%;
    top: 15%;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #16a085;
    animation: spin 1.7s linear infinite;
    z-index: 11;
    
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #e74c3c;
      animation: spin-reverse .6s linear infinite;
    }
    
    &:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #f9c922;
      animation: spin 1s linear infinite;
    }
  }
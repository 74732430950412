.goal {
	font-family: 'Montserrat', Arial, Helvetica, sans-serif;
	margin: 1.5%;
	box-sizing: border-box;
	width: 30%;
	min-height: 13rem;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	box-shadow: rgb(0 0 0 / 10%) 2px 2px 5px;
	position: relative;
}
/*scroll*/
/* .mainContainer .description .content::-webkit-scrollbar {
	width: 25px;
	background: transparent;
}
.mainContainer .description .content::-webkit-scrollbar-track {
	background: rgb(0, 0, 0, 0);
}
.mainContainer .description .content::-webkit-scrollbar-thumb {
	border: 10.5px solid transparent;
	background-clip: padding-box;
	border-radius: 9999px;
	background-color: rgb(0, 113, 194, 0.7);
} */
/* top container (number + button) */
.topContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.topContainer .button {
	cursor: pointer;
	color: #0071c2;
	font-size: 80%;
	padding: 3%;
	margin-left: 2%;
}
.goalNumber,
.goal,
.cardActionButton {
	border: 1px solid #dee3ef;
	color: '#46484A';
}
.goalNumber {
	color: #46484a;
	line-height: 1.5rem;
	font-weight: bolder;
	font-size: 90%;
	text-align: center;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;

	margin: 1.5% 0 1.5%;
	margin-inline-start: 1.5%;
}

/*main container (desc+date) */
.mainContainer {
	color: #46484a;
	margin-top: 1.5%;
	margin-inline-start: auto;
	width: 95%;
	height: 35%;
	display: flex;
}
.mainContainer .label {
	cursor: default;
	font-weight: bolder;
	font-size: 13px;
}
.mainContainer .description {
	width: 70%;
}
.content {
	cursor: pointer;
	background-color: transparent;
	font-family: 'Montserrat', Arial, Helvetica, sans-serif;
	padding: 0;
	resize: none;
	border: none;
}
.content:focus {
	outline: none;
}

.mainContainer .description .content {
	font-size: 14px;
	width: 95%;
	height: 65%;
	overflow-y: auto;
}
.mainContainer .date {
	color: #46484a;
	width: 38%;
	padding-right: 2.3%;
}
.mainContainer .date .content {
	margin-bottom: 2%;
	width: fit-content;
	width: 90%;
	font-size: 13px;
}

/*values (blockage,feedback,praise cubes) */
.values {
	color: #46484a;

	margin: 0 auto 0 auto;
	width: 100%;
	display: flex;
	font-size: 13px;
	height: 5rem;
	margin-top: 2%;
}
.value {
	width: 100%;
	padding: 2.5%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	overflow: hidden;
}
.value img {
	height: 37%;
}
.value .count {
	font-weight: bolder;
	font-size: 130%;
}

.value.blockages {
	background-color: #fef3f3;
	border-radius: 0 0 0 10px;
}
.value.blockages .count,
.buttonError {
	color: #ff4662;
}
.value.feedback_IU {
	background-color: #f0f3ff;
	border-radius: 0 0 10px 0;
}
.value.feedback_IU .count {
	color: #0071c2;
}
.value.praise_IU {
	background-color: #f1fff5;
}
.value.value.praise_IU .count {
	color: #34ab53;
}
.cardActionButton img {
	height: 100%;
	width: 100%;
}
.cardActionButton {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 1%;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: white;
	position: absolute;
	right: 55%;
	top: -6.3%;
}

.select {
	color: #46484a;

	border: none;
	width: 90%;
}
.select.disabeld {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.datepicker {
	position: absolute;
	z-index: 15;
	top: 37%;
	left: 13%;
	background-color: white;
}
.editButtonsContainer {
	margin: 2% 3% 0 0;
	height: fit-content;
	display: flex;
	width: 80%;
	justify-content: flex-end;
}
.goal.rtl .values {
	flex-direction: row-reverse;
}
@media only screen and (max-width: 1300px) {
	.goal {
		margin-top: 5%;
		width: 100%;
	}
}
@media only screen and (max-width: 600px) {
	.goal {
		width: 92%;
	}
}
.MuiInput-underline {
	display: none !important;
}
/* .statusButtonsContainer {
	display: flex;
	position: absolute;
	width: 50%;
	justify-content: space-between;
	left: 25%;
	bottom: 93.5%;
	z-index: 25;
} */
/* .statusButtonsContainer .statusButton {
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	border-radius: 50%;
	background-color: #8d9296;
	padding: 5px;
	width: 15px;
	height: 15px;
} */

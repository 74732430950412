.header {
	margin: 6% 0 2% 0;
	display: flex;
	flex-direction: column;
	background-color: #0071c2;
	width: 99.5%;
	border-radius: 10px;
	color: #ffffff;
	min-height: 220px;
}

section li {
	margin-bottom: 0.3rem;
}

section {
	padding: 1rem;
}

.expandButton {
	display: none;
}

.header h1 {
	font-size: 160%;
	margin: 0;
}

.header h2 {
	font-size: 130%;
	margin: 0;
}

.header p {
	font-size: 100% !important;
	margin: 0.8% 0.8% 0.8% 0;
}

.header p ul {
	padding: 0 0 0 1rem;
	margin: 0;
}

.header p li {
	list-style-position: outside;
	margin: 0.8% 0.8% 0.8% 0;
}

.header .info {
	font-size: 100%;
}

.label {
	color: #0071c2;
	font-weight: bold;
	border-radius: 15px;
	font-size: 80%;
	padding: 0.4% 1.5% 0.4% 1.5%;
	margin: auto 0.4rem 0.4rem 0.4rem;
	width: fit-content;
	background-color: #ffffff;
	display: flex;
}

.label img {
	margin-left: 0.5rem;
	width: 0.7rem;
}

.bottomHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 1.5%;
}

.urgency {
	padding-top: 1.4rem;
	text-transform: uppercase;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: space-between;
	height: 120px;
	min-width: 10rem;
}

.urgency img {
	margin: 3%;
	width: 3rem;
	height: 3rem;
}

.urgency b {
	font-weight: 600;
}

.sectionsContainer {
	display: flex;
}

.thumbupIcon {
	margin-top: 1%;
	width: 60px;
	align-self: center;
}

.blockageOpenTitleContainer {
	justify-content: center;
	display: flex;
}

.blockageOpenTitleContainer h1 {
	margin-top: 0 !important;
	font-size: 220%;
	align-self: center;
}

.info h1 {
	margin-top: 0.5%;
}

.expandButton {
	font-weight: bold;
	color: white;
	background-color: #004679;
	width: max-content;
	padding: 2%;
	border: none;
	position: absolute;
	bottom: 0;
	border-radius: 0 10px 0 0;
}

.header.rtl .info * {
	text-align: right;
}

.header.rtl .label {
	flex-direction: row-reverse;
}

@media only screen and (max-width: 600px) {
	.thumbupIcon {
		display: none;
	}

	.header.rtl .expandButton {
		border-radius: 10px 0 0 0;
	}

	.header {
		position: relative;
		border-radius: 0;
		width: 106%;
		min-height: 20vh;
	}

	.header .info {
		width: 100%;
	}

	.header p {
		transition: opacity 2s;
		margin-bottom: 10%;
		display: none;
	}

	.expandButton {
		display: block;
	}

	.urgency {
		min-width: 20%;
		width: 20%;
		justify-content: space-around;
		font-size: 90%;
		padding: 3% 3% 0 0;
	}

	.header.rtl .urgency {
		padding: 3% 0 0 3%;
	}

	.urgency img {
		width: 2rem;
		height: 2rem;
	}

	.header h2 {
		font-size: 120%;
	}

	.section {
		padding: 0 !important;
	}

	.label {
		display: none;
	}
}